const form = document.getElementById("contact");
const formButton = document.getElementById("submit");
const formAction = document.currentScript.getAttribute("data-action");
const formGoaway = "privacy-policy";
const delay = document.currentScript.getAttribute("data-delay");  //* 1000;

disableBtn();

document.addEventListener("DOMContentLoaded", (e) => {
  formExists = typeof form != "undefined";
  if (formExists != false) {
    setTimeout(function () {
      enableBtn();
    }, delay);
    formButton.disabled = true;
  }
});

function disableBtn() {
  formButton.disabled = true;
  form.action = formGoaway;
}

function enableBtn() {
	form.action = formAction;
  formButton.disabled = false;
}
